.sn_block_slider_solutions {
	position: relative;
	overflow: hidden;

	h2, .h2, h3, .h3 {
		color: $tertiary;
	}

	h2, .h2 {
		font-weight: fw(regular);
	}

	&_ct {
		width: 100%;
		margin: 0 auto;
		transform: translateY(100px);
		opacity: 0;
		transition: $transition-smooth;

		@include mq(sm){
			width: 75%;
		}

		@include mq(md){
			padding: 0 ($grid-gutter-width / 2);
		}

		&._in {
			opacity: 1;
			transform: translateY(0px);
		}
	}

	.swiper-container {
		position: static;
		overflow: visible;

		.swiper-wrapper {
			.swiper-slide {

				&.swiper-slide-active,
				&.swiper-slide-duplicate-active {
					.image {
						&::before {
							background-color: none;
						}
					}

					.title,
					.text,
					.btns {
						opacity: 1;
					}
				}

				.image {

					@include scut-ratio-box(1100/500);

					@include mq(lg){
						@include scut-ratio-box(1100/400);
					}
					background-size: cover;
					background-position: center center;
					background-repeat: no-repeat;

					&::before {
						background-color: none;
						transition: $transition-base;
					}
				}

				.title {
					@extend .h2;
					text-align: center;
					margin-top: 25px;
					margin-bottom: 20px;
					color: $tertiary;
				}

				.text {
					@extend .sn_text;
					text-align: center;
					color: $tertiary;
				}

				.btns {
					margin-top: 25px;
					text-align: center;

					.btn {
						margin: 0 5px 10px;
					}
				}

				.title,
				.text,
				.btns {
					opacity: 0;
					transition: $transition-base;

					@include mq(0px, sm){
						// Compensate 'padding: 0' of the '.container'
						padding: 0 ($grid-gutter-width / 2);
					}
				}
			}
		}

		.swiper-button-prev {
			@include mq(sm){ left: -70px; }
			@include mq(md){ left: -80px; }
			@include mq(lg){ left: -100px; }
		}

		.swiper-button-next {
			@include mq(sm){ right: -70px; }
			@include mq(md){ right: -80px; }
			@include mq(lg){ right: -100px; }
		}
	}
}
