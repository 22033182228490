.sn_block_banner_logo{
	height: 100vh;
	@include mq(md){
		max-height: 465px;
	}
	position: relative;
	display:flex;
	flex-wrap: wrap;
	figure{
		width:100%;
		height: 50%;
		@include mq(md){
			width: 50%;
			height: 100%;
		}
		margin:0;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	._logo{
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		display:grid;
		place-items: center;
		img{
			padding: 0 13px;
		}
	}
}
