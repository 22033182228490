@keyframes gradient {
	0% {
		background-position: 0% 100%;
	}

	100% {
		background-position: 100% 0%;
	}
}

.sn_booking{
	color:$white;
	&_form{
		background-color: $secondary;
		color:$white;
		h4{
			color:$white;
		}
		.sn_form{
			.form-group{
				margin: 0 5px;
				@include mq(lg){
					margin: 0 8px;
				}
				@include mq(xl){
					margin: 0 8px;
				}

				.form-control,.custom-select{
					color:$secondary;
					height: 40px;
					font-size: 14px;

					@include placeholder {
						color:$secondary;
					}

					@include mq(lg){
						height: 50px;
						font-size: 18px;
					}

					@include mq(xl){
						height: 55px;
						font-size: 16px;
					}

					&._fixed-small{
						width: 80px;
						@include mq(lg){
							width: 90px;
						}
						@include mq(xl){
							width: 120px;
						}
					}
					&._fixed-medium{
						width: 120px;
						@include mq(lg){
							width: 150px;
						}
						@include mq(xl){
							width: 180px;
						}
					}
					&._fixed-big{
						width: 150px;
						@include mq(lg){
							width: 200px;
						}
						@include mq(xl){
							width: 230px;
						}
					}

					option{
						color:$secondary;
						font-size: 16px;
					}
				}
			}
		}

		button{
			padding:0;
			height: 40px;
			width: 120px;
			margin: 0 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			@include mq(lg){
				height: 50px;
				margin: 0 8px;
				width: 150px;
				font-size: 18px;
			}
			@include mq(xl){
				height: 55px;
				margin: 0 8px;
				width: 180px;
				font-size: 20px;
			}
			font-size: 14px;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
		}

		&._fixed {
			position: fixed;
			top: #{$navbar-height-mobile};
			width: 100%;
			z-index: $zindex-sticky;
			@include mq(lg) {
				top: #{$navbar-height-desktop};
			}
		}
	}

	&_cta{
		padding: 15px 25px;
	}

	&._label{
		// background: linear-gradient(45deg, $primary, #f3e1bb, $primary, #f3e1bb,$primary);
		background: $primary;
		background-size: 400% 100%;
		// animation: gradient 10s linear alternate infinite;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $white;
		fill: $white;
		line-height: 1;
		cursor: pointer;
		a{
			color:$white;
			h4{
				font-size: 25px;
				margin:0;
				@include mq(md){
					font-size: 18px;
				}
				@include mq(lg){
					font-size: 20px;
				}
				@include mq(xl){
					font-size: 23px;
				}
				color: $white;
			}
			p{
				color: $white;
				margin:0;
				font-size: 15px;
				@include mq(md){
					font-size: 13px;
				}
				@include mq(lg){
					font-size: 14px;
				}
				@include mq(xl){
					font-size: 15px;
				}
			}
		}

		.sn_sprite{
			height: 40px;
			width: 30px;
			flex-shrink: 0;
		}
	}

	&_emotional {
		position: absolute;
		bottom:0;
		z-index: 2;
		margin-left: 3px;
		height: 45px;
		width: 252px;
		@include mq(lg){
			margin-left: 10px;
			width: 316px;
			height: 65px;
		}
		@include mq(xl){
			margin-left: 24px;
			width: 377px;
			height: 85px;
		}
		border-top-left-radius: 25px;
		border-top-right-radius: 25px;
		box-shadow: 0px 6px 9px 1px rgba(125, 110, 66, 0.58);
	}

}
