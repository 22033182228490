//** General

// Colors

// Zindex

// Font family
$main-font-family: 'Poppins';
$font-family-serif: $main-font-family;

// Structure
$navbar-height-mobile: 114px;
$navbar-height-desktop: 180px; // lg up

// Font Weight
$font-weights: (
  thin				: 100,
	extralight	: 200,
	light				: 300,
	regular			: 400,
	medium			: 500,
	semibold		: 600,
	bold				: 700,
	extrabold		: 800,
	ultrabold		: 900
);

// Transition
$transition-cubic-bezier: cubic-bezier(0.4, 0, 0.2, 1);
$transition-smooth: all .6s $transition-cubic-bezier;
$transition-site-message: all .6s $transition-cubic-bezier;
