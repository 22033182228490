.sn_block_images_links{
	&_i{
		align-self: center;
		display:block;
		color: $secondary;
		._image{
			@include scut-ratio-box(360/400);
			overflow: hidden;
			position: relative;
			figure{
				margin:0;
				position: absolute;
				top:0;
				left:0;
				height: 100%;
				width: 100%;
				background-color: black;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				transition: all .5s;
			}
			._text{
				position: absolute;
				display: flex;
				justify-content: flex-end;
				flex-direction: column;
				align-items: center;
				height: 100%;
				width: 100%;
				top:0;
				z-index: 1;
				line-height: 1;
				font-size: 16px;
				@include mq(lg){
					font-size: 20px;
				}
				text-transform: uppercase;
				text-align: center;
				color: $tertiary;
				font-weight: 600;
			}
		}


		/*
		&:hover{
			figure{
				transform: scale(1.1);
			}
		}*/
	}
}
