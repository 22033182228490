
.swiper-pagination {
	pointer-events: none;
	font-size: 0;

	&.swiper-pagination-bullets {
		.swiper-pagination-bullet {
			@include size(12px);
			background: none;
			transition: $transition-base;
			border: 1px solid $white;
			opacity: 1;
			pointer-events: auto;

			&.swiper-pagination-bullet-active {
				background: $white;
				pointer-events: none;
			}
		}
	}
}

.swiper-button-prev,
.swiper-button-next {
	@include size(40px, 40px);
	fill: #fff;
	margin-top: -(56px / 2);
	background: none;
	transition: $transition-base;

	@include mq(md){
		@include size(56px, 56px);
		margin-top: -(56px / 2);
	}

	&:hover,
	&:focus,
	&:active {
		fill: $primary;
	}

	.sn_sprite {
	}
}

.swiper-button-prev {
	left: 0;
}

.swiper-button-next {
	right: 0;
}
