
.sn_form {
	font-family: $main-font-family;
	&_box {
		background: $gray-200;
		padding: 25px 20px;

		@include mq(md){
			padding: 35px 30px;
		}

		&_title {
			@include font-size(20px, 30px);
			font-family: $font-family-serif;
			font-style: italic;
			line-height: 1.1;
			margin-bottom: 20px;
		}

		p {
			@include font-size(16px);
			font-weight: fw(light);
			margin: 0;
		}
	}

	.form-group {
		margin-bottom: 15px;

		.form-control {

			border-radius: 0;
			font-size: 16px;
			padding: 2px 15px;
			box-shadow: none !important;

			@include placeholder {
				font-style: italic;
			}

			&._date {
        background-image: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$body-color}' viewBox='0 0 80 80'%3e%3cpath d='M46.7,11.7h10.6V18c0,1,0.8,1.8,1.8,1.8S61,19,61,18V2.3c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8V8H46.7c-1,0-1.8,0.8-1.8,1.8 S45.7,11.7,46.7,11.7z'/%3e %3cpath d='M27.6,11.7h10.7V18c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8V2.3c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8V8H27.6 c-1,0-1.8,0.8-1.8,1.8S26.5,11.7,27.6,11.7z'/%3e %3cpath d='M72.9,8.1H66c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h6.9c1.3,0,2.4,1.1,2.4,2.4v59.2c0,1.3-1.1,2.4-2.4,2.4H7.1 c-1.3,0-2.4-1.1-2.4-2.4V14.2c0-1.3,1.1-2.4,2.4-2.4h12V18c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8V2.3c0-1-0.8-1.8-1.8-1.8 S19,1.3,19,2.3V8H7c-3.3,0.1-6,2.8-6,6.2v59.2c0,3.4,2.7,6.1,6.1,6.1h65.8c3.4,0,6.1-2.7,6.1-6.1V14.2C79,10.8,76.3,8.1,72.9,8.1z'/%3e %3cpath d='M73.7,24.2c0-1-0.8-1.8-1.8-1.8H8.4c-1,0-1.8,0.8-1.8,1.8S7.4,26,8.4,26h63.5C72.9,26,73.7,25.2,73.7,24.2z'/%3e %3cpath d='M66,45.7c1,0,1.8-0.8,1.8-1.8S67,42,66,42h-9.6v-9.3c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8V42h-11v-9.3c0-1-0.8-1.8-1.8-1.8 s-1.8,0.8-1.8,1.8V42H27.3v-9.3c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8V42h-9.4c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h9.4v10.3h-9.4 c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h9.4v9.3c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8v-9.3h10.9v9.3c0,1,0.8,1.8,1.8,1.8 s1.8-0.8,1.8-1.8v-9.3h10.9v9.3c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8v-9.3H66c1,0,1.8-0.8,1.8-1.8S67,55.9,66,55.9h-9.6V45.7H66z M27.3,55.9V45.7h10.9V56L27.3,55.9L27.3,55.9z M52.7,55.9H41.8V45.7h10.9C52.7,45.7,52.7,55.9,52.7,55.9z'/%3e%3c/svg%3e"), "#", "%23");
        background-position: right 10px center;
        background-size: 20px 20px;
        background-repeat: no-repeat;
        padding-right: 40px !important;
			}

			&:not(textarea) {
				@include mq(md){
					height: 55px;
				}
			}
		}

		textarea.form-control {
			padding: 10px 15px;
		}

		label {
			@include font-size(16px);
			font-weight: fw(light);
		}
	}

	.custom-select {
		border-radius: 0;
		font-size: 16px;
		background-size: 14px 9px;
		background-position: right 10px center;
		padding-right: 10px + 10px;
	}

	.custom-control {
		min-height: 0;
		padding-left: 20px;
		color: $body-color;
		margin-bottom: 15px;
		position: static;
		font-size: 0;

		&-label {
			position: relative;
			color: inherit;
			font-size: 12px;
			display: inline-block;
			line-height: 1;

			&::before,
			&::after {
				@include size(11px);
				border-radius: 0;
				border: 0;
				top: 6px;
				left: -20px;
				margin-top: -5px;
			}

			&::after {
				background-size: 7px 7px;
			}
		}
	}

	ul, ol {
		font-size: 14px;
	}

}

