.sn_room_detail {

	&_info {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
		@include font-size(14px);
		color: $gray-500;
		font-family: $font-family-serif;

		.date {

			span {
				display: inline-block;
				margin: 0 10px;
			}
		}
	}
	.btn-primary{
		font-size: 20px;
		@include mq(md) {
			font-size: 22px;
		}

		@include mq(lg) {
			font-size: 24px;
		}
	}
	.btn-outline-primary{
		font-size: 13px;
		padding-left: 0;
		padding-right: 0;
		@include mq(md) {
			font-size: 14px;
		}

		@include mq(lg) {
			font-size: 15px;
		}
	}
}
