.sn_grid_items {
	padding: 75px 0 (100px - 40px);
	background:  #dfe8e9;
	h2, .h2 {
		color:$white;
	}
	&._bg_dark {
		background: $secondary;
	}

	&_ct {
		transform: translateY(100px);
		opacity: 0;
		transition: $transition-smooth;

		&._in {
			opacity: 1;
			transform: translateY(0px);
		}
	}
}
