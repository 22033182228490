.sn_intro_slider {
	position: relative;

	._responsive{
		position: relative;
		@include scut-ratio-box(1920/1080);
	}

	._tall {
		position: relative;
		@include scut-ratio-box(1920/1080);
	}

	._medium {
		position: relative;
		@include scut-ratio-box(1920/750);
	}

	._short {
		position: relative;
		@include scut-ratio-box(1920/500);
	}

	// Gradient
	&::before {
		@include gradient-y(rgba(#000, .35), rgba(#000, 0));
		content: '';
	  position: absolute;
		top: 0; left: 0; right: 0;
		height: 50%;
		pointer-events: none;
		z-index: 2;
	}


	&_bg {
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&_content {
		text-align: center;
		padding: 15px;

		@include mq(md){
			padding: 15px 60px;
		}

		&, * {
			color: #fff;
		}

		// h1, h2, h3, h4, h5, h6 {
		// 	@extend .display-1;
		// 	text-shadow: none;
		// }

		img {
			display: inline-block;
			max-width: 100%;
		}

	}

	._align_left {
		color: $white;
		text-align: left;
		justify-content: left;
		padding-left: 60px;

		@include mq(lg){
			padding-left: 30px;
		}

		@include mq(xl){
			padding-left: 330px;
		}

		p {
			@extend .display-1;
			text-shadow: none;
			color: $white;
			text-align: left;
		}

	}

	&_video {
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;

		.plyr {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;

			.plyr__video-wrapper {
				height: 100%;
				padding: 0 !important;
			}
		}
	}

	.swiper-container {
		height: 100%;

		.swiper-wrapper {
			.swiper-slide {
			}
		}

		.swiper-button-prev,
		.swiper-button-next {
			display: none;

			@include mq(md){
				display: block;
			}
		}
	}
}
