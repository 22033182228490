.btn {
	font-family: $main-font-family;
	text-transform: uppercase;
	box-shadow: none !important;
	font-size: 18px;
	line-height: 1.2;
	font-weight:normal;

	@include mq(md) {
		font-size: 18px;
	}

	@include mq(lg) {
		font-size: 20px;
	}

	&.btn-sm {
		font-size: 18px;
		@include mq(lg) {
			font-size: 20px;
		}
	}

	&._fill_white{
		background-color: none;
	}

	&.btn-primary {
		color: #fff;

		&:not(:disabled):not(.disabled) {
			&:hover,
			&:active {
				color: #fff;
			}
		}
	}

	&.btn-outline-primary {
		color:$primary;
		border-color: $primary;
		&:not(:disabled):not(.disabled) {
			&:hover,
			&:active {
				background-color: $primary;
				color: $white;
			}
		}
	}
}
