.sn_block_image_text {
	background-color:  $secondary;
	h1, h2, h3, h4, h5, h6, p, & {
		color: $white;
	}

	&._image_left {
		background-color:  $white;
		h1, h2, h3, h4, h5, h6, p, & {
			color: $secondary;
		}

		.sn_block_image_text_col {
			&:first-child {
				> div {
					transform: translateX(100px);
				}
			}

			&:last-child {
				> div {
					transform: translateX(-100px);
				}
			}
		}
	}

	&_col {
		&:first-child {
			> div {
				transform: translateX(-100px);
			}
		}

		&:last-child {
			> div {
				transform: translateX(100px);
			}
		}

		> div {
			opacity: 0;
			transition: $transition-smooth;
		}

		&._in {
			> div {
				transform: translateX(0%) !important;
				opacity: 1;
			}
		}
	}

}
