.sn_block_banner_image_text{
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: #af4035;

	._figure{
		cursor: pointer;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		min-height: 250px;
		@include mq(md){
			min-height: 280px;
		}

		@include mq(lg){
			min-height: 350px;
		}
	}

	._text{
		cursor: pointer;
		padding: 40px 25px;
		min-height: 250px;
		@include mq(md){
			padding: 40px;
			min-height: 280px;
		}
		@include mq(lg){
			min-height: 350px;
		}
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items:flex-start;
		background-color: $white;
		p{
			font-weight: 300;
			color: $tertiary;
			line-height: normal;
			font-size: 20px;
			@include mq(md){
				font-size: 25px;
			}
			@include mq(lg){
				font-size: 30px;
			}
			@include mq(xl){
				font-size: 35px;
			}

		}

		.btn{
			width: 100%;
			@include mq(md){
				width: auto;
			}
		}
	}
}
