h1, .h1 {
	font-size: 35px;
	text-shadow: 0px 10px 19px rgba(10, 32, 45, 0.9);
	line-height: 1.1;
	margin-bottom: 0;
	color: $tertiary;
	@include mq(md) {
		font-size: 50px;
	}

	@include mq(xl) {
		font-size: 65px;
	}
}

h2, .h2 {
	font-size: 33px;
	font-weight: 300;
	color: $tertiary;
	@include mq(md) {
		font-size: 40px;
	}

	@include mq(xl) {
		font-size: 50px;
	}
}

h3, .h3 {
	font-size: 20px;
	color: $tertiary;

	@include mq(md) {
		font-size: 27px;
	}

	@include mq(xl) {
		font-size: 35px;
	}
}

h4, .h4 {
	font-size: 20px;
	color: $tertiary;

	@include mq(md) {
		font-size: 22px;
	}

	@include mq(xl) {
		font-size: 25px;
	}
}

h5, .h5 {
	font-size: 19px;

	@include mq(md) {
		font-size: 19px;
	}
}

h6, .h6 {
	font-size: 15px;
	font-weight: fw(bold);

	@include mq(md) {
		font-size: 19px;
	}

	@include mq(xl) {
		font-size: 20px;
	}
}


p, ul, ol {
	font-size: 16px;
	@include mq(md) {
		font-size: 20px;
	}
}

p{
	line-height: 1.4;
}

hr {
	border-color: #e6e3e3;
	margin: 40px 0;
}

strong {
	font-weight: fw(bold);
}

blockquote {
	padding-left: 95px;
	margin: 50px 0;
	position: relative;
	font-size: 20px;
	line-height: 1.28;
	font-weight: fw(regular);
	font-style: italic;

	@include mq(md) {
		font-size: 22px;
	}

	@include mq(xl) {
		font-size: 25px;
	}

	&::before {
		@include size(37px);
		content: '';
		position: absolute;
		top: 25px;
		left: 0;
		background-size: contain;
		background-position: center top;
		background-repeat: no-repeat;
		background-image: url('../images/svg/quote-open.svg');
	}

	&::after {
		@include size(2px, 100%);
		content: '';
		position: absolute;
		top: 0;
		left: 67px;
		background-color: $primary;
	}

	cite,
	sub {
		display: block;
		font-style: normal;
		font-size: 16px;
		color: $primary;
		line-height: 1;
		margin-top: 10px;
		font-weight: fw(light);

		@include mq(md) {
			font-size: 18px;
		}

		&::before {
			content: '- ';
		}

		&::after {
			content: ' -';
		}
	}

	a {
		font-style: normal;
		font-size: 16px;
		margin-top: 10px;
		font-weight: fw(light);

		@include mq(md) {
			font-size: 18px;
		}
	}
}

.display-1 {
	font-size: 40px;

	@include mq(md) {
		font-size: 55px;
	}

	@include mq(xl) {
		font-size: 65px;
	}
}

.sn_text {
	img {
		display: block;
		margin: 30px auto;
		max-width: 100%;
		height: auto;
	}
}

.wp-cookies-excerpt{
	.text-content{
		font-size: 18px;
		text-align: center;
		@include mq(md){
			text-align: left;
			font-size: 22px;
		}
	}
}

body, p {
	color: $tertiary;
}
